import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

//Model
import { ParameterizationRatesModel } from './../models/parameterization-rates.model';
import { ChangeParameterizationRatesModel } from './../models/change-parameterization-rates.model';

@Injectable({ 
    providedIn: 'root'
})
export class ParameterizationService {

    reset(arg0: {}, arg1: { onlySelf: boolean; emitEvent: boolean; }) {
        throw new Error("Method not implemented.");
    }

    constructor(
        private http: HttpClient,
    ) { }

    getDataRates(page: number, size: number) {
        return this.http.get(`${environment.baseUrl}/rate/queryrate?page=${page}&size=${size}`);
    }

    getStartDate() {
        return this.http.get(`${environment.baseUrl}/rate/startDate`);
    }

    getDataRatesById(id): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/rate/rate/${id}`);
    }

    postDataRates(dataFormModal: ParameterizationRatesModel): Observable<ParameterizationRatesModel> {
        return this.http.post<any>(
            `${environment.baseUrl}/rate`,
            dataFormModal,
        ).pipe(map(res => res), map((res: any) => {
            return res;
        }));
    }

    putDataRates(dataFormModal: ChangeParameterizationRatesModel): Observable<ChangeParameterizationRatesModel> {
        return this.http.put<any>(
            `${environment.baseUrl}/rate`,
            dataFormModal,
        ).pipe(map(res => res), map((res: any) => {
            return res;
        }));
    }

    // Manipulação de erros
    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Error lado do cliente
            errorMessage = error.error.message;
        } else {
            // Error lado do servidor
            errorMessage = `Código de erro: ${error.status},` + `menssagem: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}
